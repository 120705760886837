import React from 'react'

const DisabledEnterprises = () => {
  return (
    <div>
      Disabled Enterprises
    </div>
  )
}

export default DisabledEnterprises
