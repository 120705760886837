import React, { useState, useEffect } from "react";
import "./SignUp.css";
import StarteleLogo from '../../../Assets/Star.png'

import { ReactComponent as SignUpAnimate } from "../../../Assets/signUpAnimate.svg";
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Bars, ColorRing, FallingLines, Hourglass, RotatingLines } from 'react-loader-spinner';

import {
  styled,
  Button,
  FormControl,
  Switch,
  FormGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Box,
  OutlinedInput,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import OtpTimer from "otp-timer";
import { Password } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "0px",
  p: 3,
  animationName: "none",
};

const SignUp = () => {
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast"

  useEffect(() => {
    window.scrollTo(0, 0); // this is for scroll top of the page 
  }, []);

  const navigate = useNavigate();

  // For the Sign up Page States
  const [FullName, setFullName] = useState("");
  const [FullNameError, setFullNameError] = useState("");
  const [FullNameFlag, setFullNameFlag] = useState(true);
  const [SignUpEmail, setSignUpEmail] = useState("");
  const [SignUpMobileNo, setSignUpMobileNo] = useState("");
  const [SignupEmailError, setSignupEmailError] = useState("");
  const [SignupEmailFlag, setSignupEmailFlag] = useState(true);
  const [SignUpPassword, setSignUpPassword] = useState("");
  const [ShowSigninPassword, setShowSigninPassword] = useState(false);
  const [SignupPasswordFlag, setSignupPasswordFlag] = useState(true);
  const [SignupPasswordError, setSignupPasswordError] = useState("");
  const [RetypeSignUpPassword, setRetypeSignUpPassword] = useState("");
  const [RetypeSignupPasswordError, setRetypeSignupPasswordError] = useState("");
  const [RetypeSignupPasswordFlag, setRetypeSignupPasswordFlag] = useState(true);
  const [ShowRetypePassword, setShowRetypePassword] = useState(false);
  const [Referral, setReferral] = useState("");
  const [PromotionMail, setPromotionMail] = useState(false); // This is for checkbox to recieve promotion mails.
  const [AccountType, SetAccountType] = useState("0");
  const [CompanyName, setCompanyName] = useState("");
  const [NumberOfEmployes, setNumberOfEmployes] = useState("0");
  const [Experience, setExperience] = useState("0");
  const [DesignationName, setDesignationName] = useState("");
  const [DesignationError, setDesignationError] = useState('');
  const [AccountTypeError, setAccountTypeError] = useState('');
  const [JobExpError, setJobExpError] = useState('');
  const [MobileNoError, setMobileNoError] = useState('');
  const [NumberOfEmpError, setNumberOfEmpError] = useState('');
  const [CompanyNameError, setCompanyNameError] = useState('');




  // loader button state 
  const [ShowSignUpButtonLoader, setShowSignUpButtonLoader] = useState(false)
  const [ShowSubmitOtpLoader, setShowSubmitOtpLoader] = useState(false)

  const [OpenVerifyEmailModal, setOpenVerifyEmailModal] = useState(false); // This state is for opening Verify Email Modal.
  const SignUpEmailForOtpVerify = localStorage.getItem(
    "SignUpEmailForOtpVerify"
  );
  const [VerifyOtp, setVerifyOtp] = useState("");



  const togglePasswordVisibility = (value, SetValue) => {
    SetValue(!value);
  };

  // Sign up page validation start here
  const handleFullName = (e) => {
    const inputValue = e.target.value;
    setFullName(inputValue);

    // If the input value is blank
    if (inputValue.trim() === "") {
      setFullNameError("Please enter full name");
      setFullNameFlag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
      setFullName(alphabeticValue);
      setFullNameError("");
      setFullNameFlag(true);
    }
  };

  const handleSignUpEmailChange = (e) => {

    const SignUpEmail = e.target.value;
    setSignUpEmail(SignUpEmail);
    ValidateSignupEmail(SignUpEmail);
  };

  const ValidateSignupEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!email) {
      setSignupEmailError("Please enter your email id");
      setSignupEmailFlag(false);
    } else if (!emailPattern.test(email)) {
      setSignupEmailError("Invalid email address");
      setSignupEmailFlag(false);
    } else {
      setSignupEmailError(""); // Clear any previous error
      setSignupEmailFlag(true); // Set flag to true for valid email
    }
  };

  const handleAccountType = (e) => {
    const inputValue = e.target.value;
    if (inputValue == 0 || inputValue === "") {
      setAccountTypeError('Please select account type')
    } else {
      setAccountTypeError('')
      SetAccountType(inputValue);
    }
  }

  const handeExperience = (e) => {
    const inputValue = e.target.value;
    if (inputValue == 0 || inputValue === "") {
      setJobExpError('Please select job experience')
    } else {
      setJobExpError('');
      setExperience(inputValue);
    }
  }

  const handeNumOfEmployees = (e) => {
    const inputValue = e.target.value;
    if (inputValue == 0 || inputValue === "") {
      setNumberOfEmpError('Please select number of employees');
    } else {
      setNumberOfEmpError('');
      setNumberOfEmployes(inputValue);
    }
  }

  const handleCompanyName = (e) => {
    const inputValue = e.target.value;
    setCompanyName(inputValue);

    // If the input field is empty
    if (inputValue.trim() === "") {
      setCompanyNameError('Please enter company name');
    } else {
      // Remove non-alphabetic characters
      // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");
      setCompanyName(inputValue);
      setCompanyNameError("");
    }
  }

  const maxPasswordLength = 16;

  const handleSignUpPassword = (e) => {
    const signuppasswordValue = e.target.value;

    if (signuppasswordValue.length <= maxPasswordLength) {
      setSignUpPassword(signuppasswordValue);
    }

    if (
      signuppasswordValue.length < 6 ||
      signuppasswordValue.length > maxPasswordLength
    ) {
      setSignupPasswordFlag(false);
      setSignupPasswordError(
        "Password should be between 6 to " + maxPasswordLength + " characters"
      );
    } else {
      setSignUpPassword(signuppasswordValue);
      setSignupPasswordError("");
      setSignupPasswordFlag(true);
    }
  };

  const handleRetypeSignupPassword = (e) => {
    const RetypesignuppasswordValue = e.target.value;

    if (RetypesignuppasswordValue.length <= maxPasswordLength) {
      setRetypeSignUpPassword(RetypesignuppasswordValue);
    }

    if (
      RetypesignuppasswordValue.length < 6 ||
      RetypesignuppasswordValue.length > maxPasswordLength
    ) {
      setRetypeSignupPasswordFlag(false);
      setRetypeSignupPasswordError(
        "Password should be between 6 to " + maxPasswordLength + " characters"
      );
    } else {
      setRetypeSignUpPassword(RetypesignuppasswordValue);
      setRetypeSignupPasswordError("");
      setRetypeSignupPasswordFlag(true);
    }
  };

  const handleDesignation = (e) => {
    const inputValue = e.target.value;
    setDesignationName(inputValue);

    // If the input value is blank
    if (inputValue.trim() === "") {
      setDesignationError("Please enter designation name");
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
      setDesignationName(alphabeticValue);
      setDesignationError("");
    }
  }

  const HandleSignUp = () => {

    setShowSignUpButtonLoader(true)


    if (!FullName) {
      setFullNameError("Please enter full name");
      setFullNameFlag(false);
      setShowSignUpButtonLoader(false);
      // return;
    }

    if (!SignUpEmail) {
      setSignupEmailError("Please enter your email id");
      setSignupEmailFlag(false);
      setShowSignUpButtonLoader(false);
      // return;
    }

    if (!SignUpMobileNo) {
      setMobileNoError('Please enter mobile number');
      setShowSignUpButtonLoader(false);
      // return;
    } else {
      setMobileNoError('');
      setShowSignUpButtonLoader(false);
    }


    if (AccountType == 0 || AccountType === "") {
      setAccountTypeError("Please select account type");
      setShowSignUpButtonLoader(false);
    } else {
      setAccountTypeError("");
      setShowSignUpButtonLoader(false);
    }

    if (AccountType === "Job_Seeker" && (Experience == 0 || AccountType === "")) {
      setJobExpError("Please select job experience");
      setShowSignUpButtonLoader(false);
    } else {
      setJobExpError('');
      setShowSignUpButtonLoader(false);
    }

    if ((AccountType === "Job_Seeker" && Experience === "Fresher" || Experience === "Experience") && !DesignationName) {
      setDesignationError('Please enter designation name');
      setShowSignUpButtonLoader(false);
      // return;
    }

    if (AccountType === "Enterprise" && (NumberOfEmployes == 0)) {
      setNumberOfEmpError('Please select number of employees');
      setShowSignUpButtonLoader(false);
    } else {
      setNumberOfEmpError('');
      setShowSignUpButtonLoader(false);
    }

    if (AccountType === "Enterprise" && (NumberOfEmployes != 0) && !CompanyName) {
      setCompanyNameError('Please enter company name');
      setShowSignUpButtonLoader(false);
    } else {
      setCompanyNameError('');
      setShowSignUpButtonLoader(false);
    }

    if (SignUpPassword.length <= maxPasswordLength) {
      setSignUpPassword(SignUpPassword);
    }

    if (
      SignUpPassword.length < 6 ||
      SignUpPassword.length > maxPasswordLength
    ) {
      setSignupPasswordFlag(false);
      setSignupPasswordError(
        "Password should be between 6 to " + maxPasswordLength + " characters");
      setShowSignUpButtonLoader(false);
      // return;
    }

    if (RetypeSignUpPassword.length <= maxPasswordLength) {
      setRetypeSignUpPassword(RetypeSignUpPassword);
    }

    if (
      RetypeSignUpPassword.length < 6 ||
      RetypeSignUpPassword.length > maxPasswordLength
    ) {
      setRetypeSignupPasswordFlag(false);
      setRetypeSignupPasswordError(
        "Password should be between 6 to " + maxPasswordLength + " characters"
      );
      setShowSignUpButtonLoader(false);
      // return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (
      !FullName ||
      !SignUpEmail ||
      !SignUpPassword ||
      !RetypeSignUpPassword ||
      !SignUpMobileNo ||
      !AccountType ||
      (AccountType === "Job_Seeker" && Experience === "0") ||
      (AccountType === "Job_Seeker" && DesignationName === "") ||
      (AccountType === "Enterprise" && NumberOfEmployes === "0") ||
      (AccountType === "Enterprise" && !CompanyName)
    ) {
      toast.warn("Please fill in all required fields.", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }

    if (!FullNameFlag) {
      toast.warn("Please enter Full name", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }

    if (!SignupEmailFlag) {
      toast.warn("Please enter valid email address", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }

    if (!SignupPasswordFlag) {
      toast.warn("Please enter password.   ", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }

    if (SignUpPassword != RetypeSignUpPassword) {
      toast.warn("The password and re-entered password do not match.", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }

    if (!RetypeSignupPasswordFlag) {
      toast.warn("Please enter Retype  password", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSignUpButtonLoader(false)
      return;
    }
    if (SignUpMobileNo.length <= 11) {
      toast.warn('Please enter valid phone number', {
        position: 'top-right',
        autoClose: 2000,
      })
      setShowSignUpButtonLoader(false)
      return;
    }

    const rawObject = {
      full_name: FullName,
      email_id: SignUpEmail,
      password: SignUpPassword,
      referral_code: Referral,
      mobile_number: SignUpMobileNo,
      account_type: AccountType,
    };

    localStorage.setItem("SignUpEmailForOtpVerify", SignUpEmail);

    if (AccountType === "Enterprise") {
      rawObject.employees = NumberOfEmployes;
      rawObject.company_name = CompanyName;
      rawObject.experience = "";
      rawObject.designation = "";
    } else if (AccountType === "Job_Seeker") {
      rawObject.experience = Experience;
      rawObject.designation = DesignationName

      rawObject.employees = "";
      rawObject.company_name = "";
    }
    const raw = JSON.stringify(rawObject);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

   

    fetch(`${REACT_APP_BASE_API_URL}/signup`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          setOpenVerifyEmailModal(true);
          setShowSignUpButtonLoader(false)
        } else {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 3000,
          });
          setShowSignUpButtonLoader(false)
          return;
        }
      });
  };

  const HandleVerifyEmail = () => {
    setShowSubmitOtpLoader(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (!VerifyOtp) {
      toast.warn("Please enter the OTP.", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      setShowSubmitOtpLoader(false)
      return;
    }

    if (VerifyOtp.length < 6 || VerifyOtp.length > MaxOTPLength) {
      toast.warn('OTP length should be 6 digits', {
        position: 'top-right',
        autoClose: 2000
      })
      return;
    }

    const raw = JSON.stringify({
      email_id: SignUpEmailForOtpVerify,
      otp: VerifyOtp,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/verify_email`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          localStorage.removeItem("SignUpEmailForOtpVerify");
          setShowSubmitOtpLoader(false)
          setOpenVerifyEmailModal(false);
          navigate("/login");
          setVerifyOtp("");
        } else {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          setShowSubmitOtpLoader(false)
          setVerifyOtp("");
          return;
        }
      });
  };

  const HandleResendOtp = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email_id: SignUpEmailForOtpVerify,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/resend_otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
        }
        else {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return
        }

      });
  };

  // variable for otp length and opt value 
  const MaxOTPLength = 6

  const handleVerifyOTP = (e) => {
    const OTPValue = e.target.value;

    if (OTPValue.length <= MaxOTPLength) {
      setVerifyOtp(OTPValue);
    }

  }



  return (
    <div className="SignUp">
      <div className="Box_1">
        <SignUpAnimate />
      </div>

      <div className="Box_2">
        <div id="LoginTitle">
          <H1>Sign Up Page </H1>
          <img src={StarteleLogo} />
        </div>

        <form id="login_data">
          <div>
            <TextField
              fullWidth
              label={
                <span>
                  Full Name<span style={{ color: 'red' }}> *</span>
                </span>
              }
              variant="outlined"
              inputProps={{ tabIndex: "1" }}
              value={FullName}
              onChange={handleFullName}
              autoComplete="off"
            // style={{border: !FullNameFlag ? '1px solid red': ''}}
            />
            {FullNameError && (
              <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{FullNameError}</p>
            )}
          </div>

          <div>
            <TextField
              fullWidth
              label={
                <span>
                  Email Id<span style={{ color: 'red' }}> *</span>
                </span>
              }
              variant="outlined"
              inputProps={{ tabIndex: "2" }}
              value={SignUpEmail}
              onChange={handleSignUpEmailChange}
              autoComplete="off"
              style={{ color: "#666666" }}
            />
            {SignupEmailError && (
              <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{SignupEmailError}</p>
            )}
          </div>

          <>


            <PhoneInput
              country={"in"}
              enableSearch={true}
              inputProps={{ tabIndex: "3" }}
              value={SignUpMobileNo}
              onChange={(mobile) => setSignUpMobileNo(mobile)}
              style={{
                width: "100%",
              }}
              inputStyle={{
                background: "transparent",
                width: "100%",
                height: '54px'
              }}
            />
            {MobileNoError && (
              <p style={{ color: "red", fontSize: "13px", }}>{MobileNoError}</p>
            )}
          </>


          <div>
            <div className="SelectElement" >


              <select value={AccountType}
                tabIndex="4"
                // onChange={(e) => SetAccountType(e.target.value)}
                onChange={handleAccountType}
              >
                <option value="0" disabled> -- Select Account Type<span style={{ color: 'red' }}> *</span> --</option>
                <option value="Enterprise">Enterprise</option>
                <option value="Job_Seeker"> Job Seeker </option>
              </select>

            </div>
            {AccountTypeError && (
              <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{AccountTypeError}</p>
            )}
          </div>

          {AccountType == "Enterprise" && (
            <>
              <div>
                <div className="SelectElement" >
                  <select value={NumberOfEmployes}
                    tabIndex="5"
                    // onChange={(e) => setNumberOfEmployes(e.target.value)}
                    onChange={handeNumOfEmployees}
                  >
                    <option value="0" disabled> -- Select Company Size<span style={{ color: 'red' }}> *</span> -- </option>
                    <option value="1_50"> &lt; 50 Employees</option>
                    <option value="50_100"> 50 - 100 Employees</option>
                    <option value="100_200"> 100 - 200 Employees </option>
                    <option value="200+"> &gt; 200 Employees </option>
                  </select>
                </div>
                {NumberOfEmpError && (
                  <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{NumberOfEmpError}</p>
                )}
              </div>

              <TextField
                fullWidth
                label={<span>Company Name<span style={{ color: 'red' }}> *</span></span>}
                variant="outlined"
                inputProps={{ tabIndex: "6" }}
                value={CompanyName}
                autoComplete="off"
                // onChange={(e) => {
                //   setCompanyName(e.target.value);
                // }}
                onChange={handleCompanyName}
                style={{ color: "#666666" }}
              />
              {CompanyNameError && (
                <p style={{ color: "red", fontSize: "13px" }}>{CompanyNameError}</p>
              )}
            </>
          )}

          {AccountType == "Job_Seeker" && (
            <>
              <div>


                <div className="SelectElement" >
                  <select value={Experience}
                    tabIndex="5"
                    // onChange={(e) => setExperience(e.target.value)}
                    onChange={handeExperience}

                  >
                    <option value="0" disabled>--Select Job Experience<span style={{ color: 'red' }}> *</span>--</option>
                    <option value="Experience"> Experience </option>
                    <option value="Fresher"> Fresher </option>
                  </select>


                </div>
                {JobExpError && (
                  <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{JobExpError}</p>
                )}
              </div>

              <TextField

                fullWidth
                label={
                  <span>
                    Designation<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                variant="outlined"
                inputProps={{ tabIndex: "6" }}
                value={DesignationName}
                autoComplete="off"
                // onChange={(e) => {
                //   setDesignationName(e.target.value);
                // }}
                onChange={handleDesignation}
                style={{ color: "#666666", }}
              />
              {DesignationError && (
                <p style={{ color: "red", fontSize: "13px", }}>{DesignationError}</p>
              )}
            </>
          )}

          <div>
            <div style={{ position: "relative", width: "100%", }} >
              <TextField
                fullWidth
                type={ShowSigninPassword ? "text" : "password"}
                label={<span>Password<span style={{ color: 'red' }}> *</span></span>}
                variant="outlined"
                inputProps={{ tabIndex: "7" }}
                value={SignUpPassword}
                autoComplete="off"
                onChange={handleSignUpPassword}
              />
              <span
                onClick={() =>
                  togglePasswordVisibility(
                    ShowSigninPassword,
                    setShowSigninPassword
                  )
                }
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "40%",
                  right: "5%",
                }}
              >
                {ShowSigninPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {SignupPasswordError && (
              <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{SignupPasswordError}</p>
            )}
          </div>

          <div>
            <div style={{ position: "relative", width: "100%", }} >
              <TextField
                fullWidth
                type={ShowRetypePassword ? "text" : "password"}
                label={<span>Retype Password<span style={{ color: 'red' }}> *</span></span>}
                variant="outlined"
                inputProps={{ tabIndex: "8" }}
                value={RetypeSignUpPassword}
                autoComplete="off"
                onChange={handleRetypeSignupPassword}
              />
              <span
                onClick={() =>
                  togglePasswordVisibility(
                    ShowRetypePassword,
                    setShowRetypePassword
                  )
                }
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "40%",
                  right: "5%",
                }}
              >
                {ShowRetypePassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {RetypeSignupPasswordError && (
              <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{RetypeSignupPasswordError}</p>
            )}
          </div>

          {AccountType == "Job_Seeker" &&
            <TextField
              fullWidth
              label="Referral Employee Code ( Optional )"
              variant="outlined"
              inputProps={{ tabIndex: "9" }}
              value={Referral}
              autoComplete="off"
              onChange={(e) => setReferral(e.target.value)}
            />
          }

          <FormControl
            component="fieldset"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={PromotionMail}
                  onChange={() => setPromotionMail(!PromotionMail)}
                />
              }
              label={
                <Typography
                  style={{
                    fontSize: "17px",
                    color: "black",
                    fontWeight: "300",
                  }}
                >
                  I'd like to recieve promotional emails.
                </Typography>
              }
            />
          </FormControl>
        </form>

        <Button
          onClick={HandleSignUp}
          // type="submit"
          style={{
            width: "95%",
            fontSize: "15px",
            color: "white",
            border: "1px solid #011015",
            backgroundColor: "#176D96",
            border: "0px ",
            borderRadius: "10px",
            margin: "10px 0px ",
          }}
        >
          {ShowSignUpButtonLoader ?
            <RotatingLines
              visible={true}
              height="26"
              width="26"
              color="white"
              strokeWidth="5"
              animationDuration="1"
              strokeColor='white'
            /> : " AGREE AND SIGN UP"
          }
        </Button>

        <p
          style={{
            fontSize: "15px",
            fontWeight: "300",
            letterSpacing: ".5px",
            lineHeight: "20px",
            margin: "10px",
            textAlign: "justify",
          }}
        >
          By signing up for an startele-hire account, I agree to the{" "}
          <span style={{ fontWeight: "400", textDecoration: "underline" }}>
            {" "}
            Terms & Conditions.
          </span>{" "}
          Learn more about how we use and protect your data in our{" "}
          <span style={{ fontWeight: "400", textDecoration: "underline" }}>
            Privacy Policy.
          </span>
        </p>
      </div >

      {/* Modal for veryfing the OTP Mail */}
      < Modal open={OpenVerifyEmailModal} >
        <Box sx={ModalStyle2}>
          <div
            className="box2_1"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <H2
              style={{
                fontSize: "16px",
                width: "100%",
                fontWeight: "300",
                margin: "10px 0px",
              }}
            >
              A Verificatioin Email with OTP has been sent to your
              <span style={{ color: "#E25141", textDecoration: "underline" }}>
                {" "}
                {SignUpEmailForOtpVerify}{" "}
              </span>{" "}
              ,
              <br /> Please Verify it.
            </H2>

            <div
              className="inputInfoBox "
              style={{ width: "85%", marginBottom: "20px" }}
            >
              <OutlinedInput
                autoFocus={true}
                type="text"
                inputProps={{ tabIndex: "1" }}
                value={VerifyOtp}
                // onChange={(e) => setVerifyOtp(e.target.value)}
                onChange={handleVerifyOTP}
                style={{ width: "100%", height: "40px" }}
                placeholder="Enter the OTP here."

              />

              <H2
                style={{
                  fontSize: "14px",
                  fontWeight: "200",
                  marginTop: "10px",
                }}
              >
                <OtpTimer
                  seconds={59}
                  minutes={1}
                  resend={HandleResendOtp}
                  background={"#FFFFFF"}
                  buttonColor={"#E25141"}
                  ButtonText="Click to resend OTP"
                  style={{ cursor: 'pointer' }}
                />
              </H2>
            </div>

            <div>
              <Button
                onClick={HandleVerifyEmail}
                style={{
                  color: "green",
                  marginRight: "20px",
                }}
                variant="outlined"
                color="inherit"
              >
                {ShowSubmitOtpLoader ?
                  <RotatingLines
                    visible={true}
                    height="26"
                    width="26"
                    color="white"
                    strokeWidth="5"
                    animationDuration="1"
                    strokeColor='green'
                  /> : "Submit"
                }
              </Button>

              <Button
                onClick={() => {
                  setOpenVerifyEmailModal(false);
                  setVerifyOtp("");
                }}
                style={{ color: "indianRed" }}
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal >
    </div >
  );
};

export default SignUp;
