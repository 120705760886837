import React, { useEffect } from 'react';
import './EnterpriseHomePage.css';
import { H1, H2 } from '../Typography';
import Avatar from '../../Assets/Avtar.png'
import { Button, ToggleButton, Tooltip, Select, MenuItem } from '@mui/material';
import { Bookmark, BookmarkBorderOutlined, BusinessCenterOutlined, BusinessOutlined, GpsFixed, HouseRounded, LocationOn, LocationOnOutlined, QueryStatsRounded, WorkHistoryRounded, WorkOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/StarteleLogo.png';
import Card_1 from '../../Assets/Card_1.png'
import Card_2 from '../../Assets/Card_2.png'
import Card_3 from '../../Assets/Card_3.png'
import Card_4 from '../../Assets/Card_4.png'
import { useState } from 'react';
import ReactEcharts from "echarts-for-react";
import { toast } from 'react-toastify';
import { Bars, Hourglass } from 'react-loader-spinner';


const EnterpriseHomePage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");
    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID");

    const [CompanyName, setCompanyName] = useState(localStorage.getItem('CompanyName'))
    const [CompanyType, setCompanyType] = useState("")
    const [CompanyAddress, setCompanyAddress] = useState("")
    const [CompanyCity, setCompanyCity] = useState("")
    const [CompanyState, setCompanyState] = useState("")
    const [CompanyTagLine, setCompanyTagLine] = useState("")

    const [ViewCompanyDetailsLoader, setViewCompanyDetailsLoader] = useState(true)

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setViewCompanyDetailsLoader(false)
                setCompanyName(result.enterprises[0].company_name)
                setCompanyTagLine(result.enterprises[0].company_tag_line)
                setCompanyAddress(result.enterprises[0].company_address)
                setCompanyCity(result.enterprises[0].company_city)
                setCompanyState(result.enterprises[0].company_state)
                setCompanyType(result.enterprises[0].company_type)
            })
    }


    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            ViewEnterpriseDetails()
        } else {
            setViewCompanyDetailsLoader(false)
        }
    }, []);


    // Enterprise graph states start here 

    const [months, setMonths] = useState([]);

    const [SelectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
    // const [SelectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1).toString());

    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };

    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1950, currentYear);

    const handleYear = (e) => {
        setSelectedYear(e.target.value);
    };

    const staticData = [
        {
            name: "January",
            value: 12,
        },
        {
            name: "February",
            value: 0,
        },
        {
            name: "March",
            value: 22,
        },
        {
            name: "April",
            value: 0,
        },
        {
            name: "May",
            value: 0,
        },
        {
            name: "June",
            value: 0,
        },
        {
            name: "July",
            value: 9,
        },
        {
            name: "August",
            value: 0,
        },
        {
            name: "September",
            value: 0,
        },
        {
            name: "October",
            value: 0,
        },
        {
            name: "November",
            value: 12,
        },
        {
            name: "December",
            value: 0,
        },

    ]

    const getOption = () => {

        return {
            baseOption: {
                timeline: {
                    show: false,
                    autoPlay: true,
                    axisType: "category",
                    bottom: 20,
                    data: months,
                    height: null,
                    inverse: true,
                    left: null,
                    orient: "vertical",
                    playInterval: 500,
                    right: 50,
                    top: 20,
                    width: 55,
                    label: {
                        normal: {
                            textStyle: {
                                color: "#aaa",
                            },
                        },
                        emphasis: {
                            textStyle: {
                                color: "#333",
                            },
                        },
                    },
                    symbol: "none",
                    lineStyle: {
                        color: "#aaa",
                    },
                    checkpointStyle: {
                        color: "#354EF6",
                        borderColor: "transparent",
                        borderWidth: 2,
                    },
                    controlStyle: {
                        showNextBtn: false,
                        showPrevBtn: false,
                        normal: {
                            color: "#354EF6",
                            borderColor: "#354EF6",
                        },
                        emphasis: {
                            color: "#5d71f7",
                            borderColor: "#5d71f7",
                        },
                    },
                },

                color: ["#a29bfe"],

                title: {
                    subtext: "",
                    textAlign: "left",
                    left: "3%",
                    top: 10,
                },

                tooltip: { backgroundColor: "#dfe6e9", borderWidth: 0, padding: 12 },

                legend: {
                    data: ["data"],
                    itemGap: 35,
                    itemHeight: 18,
                    right: "11%",
                    top: 20,
                    show: false,
                },

                calculable: true,

                grid: {
                    top: 80,
                    bottom: 130,
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                            label: {
                                show: true,
                                formatter: function (params) {
                                    return params.value.replace("\n", "");
                                },
                            },
                        },
                    },
                },
                xAxis: [
                    {
                        axisLabel: {
                            interval: 0,
                            rotate: 40,
                            textStyle: {
                                baseline: "top",
                                color: "#333",
                                fontSize: 10,
                                fontWeight: "bold",
                            },
                        },
                        axisLine: { lineStyle: { color: "#aaa" }, show: true },
                        axisTick: { show: false },
                        data: staticData.map((item) => item.name),
                        splitLine: { show: false },
                        type: "category",
                    },
                ],

                yAxis: [
                    {
                        axisLabel: {
                            textStyle: { fontSize: 11 },
                        },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        name: "Profile view",
                        splitLine: {
                            lineStyle: {
                                type: "dotted",
                            },
                        },
                        type: "value",
                    },
                ],

                series: [
                    {
                        name: "Profile visit",
                        type: "line",
                        barWidth: '30%',
                        data: staticData.map((item) => item.value),
                    },
                ],
            },
            options: months.map((month) => ({
                series: [
                    {
                        stack: "group",
                        data: staticData,
                    },
                ],
                title: {
                    text: "",
                },
            })),
        };
    };

    const NotAllowedCreateVacancy = () => {
        toast.warn("Please complete the company profile to proceed", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
        });
        navigate('/company_profilePage')
    }

    return (
        <div className='EnterpriseHomePage' >
            <div className='profile_section'>
                <div className='Profile_left' >
                    <div id="one">
                        <div id="welcome">
                            <h2>Welcome to <span>startele-Hire </span> </h2>
                            <p> Introducing our AI-powered interview platform, meticulously designed to revolutionize the hiring process for enterprises. Enhance your recruitment efficiency and precision with our intelligent assessment tools. Experience seamless, efficient, and effective hiring, and connect with a vast network of talented professionals, ensuring you find the perfect fit for your esteemed organization. </p>
                        </div>
                    </div>

                    <div id="two">
                        <h2>Analytics <span><QueryStatsRounded style={{ fontSize: '34px' }} /> </span> </h2>

                        <div className="cardBox">
                            <div className="card" onClick={() => navigate('/all_vacancies')} >
                                <img src={Card_1} />
                                {/* <h2>10</h2> */}
                                <h3>Created Vacancies</h3>
                                <p>These are the total vacanies currently available in the company. </p>
                            </div>

                            {CheckCompanyProfileID == 'false' ?
                                <div className="card" onClick={NotAllowedCreateVacancy} >
                                    <img src={Card_4} />
                                    {/* <h2>109</h2> */}
                                    <h3>Create Vacancy</h3>
                                    <p>Create a new vacancy by providing the required job details</p>
                                </div> :
                                <div className="card" onClick={() => navigate('/create_vacancy')} >
                                    <img src={Card_4} />
                                    {/* <h2>109</h2> */}
                                    <h3>Create Vacancy</h3>
                                    <p>Create a new vacancy by providing the required job details</p>
                                </div>}



                            <div className="card" onClick={() => navigate('/total_applied-candidates')}>
                                <img src={Card_3} />
                                {/* <h2>20</h2> */}
                                <h3>Applied Candidates</h3>
                                <p>These are the list of all the applied candidates</p>
                            </div>

                            <div className="card">
                                <img src={Card_2} />
                                {/* <h2>10</h2> */}
                                <h3>Saved Candidates</h3>
                                <p>List of Saved Candidates from Previous Interviews  </p>
                            </div>
                        </div>
                    </div>

                    <div className='Enterprise_graph'>
                        <h2>Profile Visits <span><QueryStatsRounded style={{ fontSize: '34px' }} /> </span> </h2>

                        <ReactEcharts
                            style={{ height: '90vh', width: '100%' }}
                            option={getOption()}
                            opts={{ renderer: "svg" }}
                        />

                        <div className='Enterprise_date_select'>
                            <Select
                                value={SelectedYear}
                                onChange={handleYear}
                                displayEmpty
                                style={{ height: "30px", width: "10%", margin: "10px 10px" }}
                                MenuProps={{
                                    getcontentanchorel: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="0" disabled>
                                    Select a year
                                </MenuItem>
                                {
                                    yearsArray.map((year, index) => {
                                        return <MenuItem key={index} value={year}>{year}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='Profile_right' >
                    {ViewCompanyDetailsLoader ?
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: 'column',
                            height: '300px',
                        }} >
                            <Bars visible={true} height="30" width="30" color="#749BBC" />
                            <p style={{ padding: '10px 0px ' }}>Loading data !!</p>
                        </div>
                        :
                        <div id='one' >
                            <div id="profile">
                                <div id='ProfileImage'>
                                    <img src={Logo} />
                                </div>
                            </div>
                            <h2>{CompanyName ? CompanyName : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}  > Add Company Name  </span>}</h2>
                            <p>{CompanyType ? CompanyType : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add Company Type  </span>} </p>
                            <p> <LocationOn id="icon" />
                                {CompanyCity ? CompanyCity : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')} > Add City  </span>} , {" "}
                                {CompanyState ? CompanyState : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}> Add State  </span>}
                            </p>
                            <p>{CompanyTagLine ? CompanyTagLine : <span id='AddDetailButton' onClick={() => navigate('/company_profilePage')}> Add Tag Line  </span>} </p>
                            <Button variant="contained"
                                onClick={() => navigate('/company_profilePage')}
                                style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    color: 'white',
                                    border: "1px solid #4B64FF",
                                    backgroundColor: '#4B64FF',
                                    border: '0px ',
                                    marginTop: '10px'
                                }}
                            >
                                View More
                            </Button>
                        </div>
                    }
                </div>


            </div>
        </div>
    )
}

export default EnterpriseHomePage;
