import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutputWindow from "./OutputWindow";
import OutputDetails from "./OutputDetails";
import { Button, FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import './CodeEditorScreen.css'

const CodeEditorScreen = ({ handleAnswerTextFrom }) => {
    const customId = "forNotShowingMultipleToast"

    const [code, setCode] = useState("");
    const [customInput, setCustomInput] = useState("");
    const [outputDetails, setOutputDetails] = useState(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const SendDataToCodingRound = () => {
            handleAnswerTextFrom(code)
        }
        SendDataToCodingRound()
    }, [code])


    const LanguageList = [
        { id: 63, name: "JavaScript (Node.js 12.14.0)", label: "JavaScript (Node.js 12.14.0)", value: "javascript" },
        { id: 86, name: "Java", label: "Java", value: "java" },
        { id: 87, name: "Python", label: "Python", value: "python" },
        { id: 88, name: "C#", label: "C#", value: "csharp" },
        { id: 89, name: "C++", label: "C++", value: "cpp" },
        { id: 92, name: "Go", label: "Go", value: "go" },
        { id: 99, name: "TypeScript", label: "TypeScript", value: "typescript" },
    ];

    const [language, setLanguage] = useState(LanguageList[0]);

    const onChange = (action, data) => {
        if (action === "code") {
            setCode(data);
        }
    };

    const handleCompile = () => {
        // console.log("code compiled");
        setProcessing(true);

        const formData = {
            language_id: language.id,
            source_code: btoa(code), // Encode source code in base64
            stdin: btoa(customInput),
        };

        const headers = {
            "Content-Type": "application/json",
            "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
            "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        };

        fetch(`${process.env.REACT_APP_RAPID_API_URL}?base64_encoded=true&fields=*`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log("res.data", data);
                const token = data.token;
                checkStatus(token);
            })
            .catch((err) => {
                let error = err.message || err;
                setProcessing(false);
                // console.log(error);
            });
    };

    const checkStatus = (token) => {
        const url = `${process.env.REACT_APP_RAPID_API_URL}/${token}?base64_encoded=true&fields=*`;
        const headers = {
            "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
            "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
        };

        fetch(url, {
            method: "GET",
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                let statusId = data.status?.id;

                if (statusId === 1 || statusId === 2) {
                    setTimeout(() => {
                        checkStatus(token);
                    }, 2000);
                } else {
                    setProcessing(false);
                    setOutputDetails(data);
                    showSuccessToast(`Compiled Successfully!`);
                    // console.log("response.data", data);
                }
            })
            .catch((err) => {
                // console.log("err", err);
                setProcessing(false);
                showErrorToast();
            });
    };

    const showSuccessToast = (msg) => {
        toast.success(msg || `Compiled Successfully!`, {
            position: "top-right",
            toastId: customId,
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const showErrorToast = (msg) => {
        toast.error(msg || `Something went wrong! Please try again.`, {
            position: "top-right",
            toastId: customId,
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="CodeEditorScreen" >
                <div className="CodingScreen" >
                    <FormControl >
                        <Select
                            value={language.value}
                            onChange={(e) => setLanguage(LanguageList.find(lang => lang.value === e.target.value))}
                            style={{
                                width: "100%",
                                height: "50px",
                                color: "black",
                                backgroundColor: 'white',
                                margin: "12px 0px",
                                zIndex: '10000'
                            }}
                        >
                            {LanguageList.map((language, index) => {

                                return (
                                    <MenuItem key={index} value={language.value} style={{ zIndex: '10000' }} >
                                        {language.name}{" "}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>


                    <CodeEditorWindow
                        code={code}
                        onChange={onChange}
                        language={language.value}
                    />
                </div>

                {/* <div className="ResultBox" >
                    <OutputWindow outputDetails={outputDetails} />

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px 10px", opacity: !code ? "50%" : "100%", width: 'fitContent' }}
                        onClick={handleCompile}
                        disabled={!code}
                    >
                        {processing ? "Processing..." : "Compile Code"}
                    </Button>

                    <OutputDetails outputDetails={outputDetails} />
                </div> */}
            </div>
        </>
    );
};
export default CodeEditorScreen;