import React from 'react';
import './ErrorPage.css';
import ErrorGif from '../../Assets/Error.gif'
import { Button } from '@mui/material';

const ErrorPage = () => {
    const HandleRefresh = () => {
        window.location.reload()
    }

    return (
        <div className='ErrorPage' >
            <img src={ErrorGif} id='ErrorGif' />

            <h2> Something went <span> Wrong </span> </h2>

            <p>Please refresh the page to continue further .</p>

            <Button
                className="Button_1"
                variant="contained"
                onClick={HandleRefresh}
            >
                Refresh Page
            </Button>
        </div>
    )
}

export default ErrorPage
