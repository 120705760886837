import React, { useEffect, useRef, useState } from "react";
import "./ExtemporeRound.css";
import { Bars, DNA, Hourglass } from "react-loader-spinner";
import { Box, Button, Modal, OutlinedInput } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import StarteleName from "../../../Assets/StarteleLogo.png";

import { Done, Padding, Stream, WarningAmber } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { toast } from "react-toastify";
import CompletedGif from '../../../Assets/Completed.gif'

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  borderRadius: "0px",
  border: "none",
  animationName: "none",
};

const ExtemporeRound = () => {
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen status
  const assessmentStartedRef = useRef(false);
  const cheatingStateRef = useRef(false);

  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast";

  const navigate = useNavigate();
  useEffect(() => {
    // Push a new state to the history stack to prevent going back
    window.history.pushState(null, null, window.location.pathname);

    // Listen for popstate event which is triggered by back/forward navigation
    const handlePopState = () => {
      window.history.pushState(null, null, window.location.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const componentRef = useRef(null);

  const handleFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
    setIsFullscreen(true); // Set to true when entering fullscreen
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullscreen(false); // Reset to false when exiting fullscreen
    setShowMarksModal(true); // Show the marks modal when exiting fullscreen intentionally
  };

  useEffect(() => {
    // This function is to give access to the earphones for browser.
    navigator.mediaDevices.getUserMedia({ audio: true }).catch((error) => {
      console.error("Error accessing media devices.", error);
    });
  }, []);

  const UserUuid = localStorage.getItem("UserUuid");
  const VacancyIdForStartAssessment = localStorage.getItem(
    "VacancyIdForStartAssessment"
  );
  const [InstructionsModal, setInstructionsModal] = useState();
  const [CompletedExamModal, setCompletedExamModal] = useState();
  const [ShowMarksModal, setShowMarksModal] = useState(false);
  const [ShowCheatingMarksModal, setShowCheatingMarksModal] = useState(false);

  const [showDna, setShowDna] = useState(false);

  const [convertedText, setConvertedText] = useState("");
  const [ShowAnswerText, setShowAnswerText] = useState("");

  const [StartTimer, setStartTimer] = useState(false);
  const [TimeLimit, setTimeLimit] = useState(100);

  const [questionText, setQuestionText] = useState("");
  const [roundType, setRoundType] = useState("extempore");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [roundNo, setRoundNo] = useState("1/4");
  const [questionId, setQuestionId] = useState("");
  // const [MarksObtained, setMarksObtained] = useState("");
  const MarksObtained = localStorage.getItem("MarksObtained")

  const [MarksObtainedLoader, setMarksObtainedLoader] = useState(false);

  const [ShowMainPageLoader, setShowMainPageLoader] = useState(true);

  useEffect(() => {
    const ShowVacancyRoundDetails = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        user_uuid: UserUuid,
        vacancy_uuid: VacancyIdForStartAssessment,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_API_URL}/assessment_result`,
          requestOptions
        );
        const apiResponse = await response.json();

        const isRoundPresent = apiResponse.assessment.some(
          (assessment) => assessment.round_no === "1/4"
        );

        if (isRoundPresent) {
          setShowMainPageLoader(false)
          setCompletedExamModal(true);
        } else {
          if (AssessmentStarted == "true") {
            setShowMainPageLoader(false);
            AutoSubmitAnswer();
            setInstructionsModal(false);
          } else if (AssessmentStarted == "false") {
            setShowMainPageLoader(false);
            setInstructionsModal(true);
          } else if (AssessmentStarted == "done") {
            setShowMainPageLoader(false);
            setInstructionsModal(false);
            setCompletedExamModal(false);
            setShowMarksModal(true);
          }
        }
        // console.log(isRoundPresent);
      } catch (error) {
        console.error(error);
      }
    };
    const AssessmentStarted = localStorage.getItem("AssessmentStarted");
    if (AssessmentStarted == "true") {
      AutoSubmitAnswer();
    }
    ShowVacancyRoundDetails();
  }, []);

  //This function is for the  initilizing the assessment
  const InitializeAssessment = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      vacancy_uuid: VacancyIdForStartAssessment,
      round_no: roundNo,
      round_type: roundType,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/interview_assessment`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setRoundType(result.interview_detail[0].round_type);
        setDifficultyLevel(result.interview_detail[0].difficulty_level);
        // setTimeLimit(result.interview_detail[0].timer);
        // setRoundNo(result.interview_detail[0].round_no);

        HandleInterviewDetails(
          // result.interview_detail[0].round_type,
          result.interview_detail[0].difficulty_level,
          result.interview_detail[0].question,
          result.interview_detail[0].limit
        );
      });
  };

  // This function is for handeling the interview details & fetching the Question , QuestionId , RoundId
  const HandleInterviewDetails = (difficulty_level, questionTopic, limit) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      round_type: roundType,
      difficulty_level: difficulty_level,
      questions: questionTopic,
      limit: limit,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_AI_API_URL}/interview/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setQuestionId(result.question_ids);
          setQuestionText(result.questions);

          StartRecording(result.round_id, result.question_ids);
          setStartTimer(true);
          setShowDna(true);
        }
      });
  };

  const StartAssessment = () => {
    localStorage.setItem("AssessmentStarted", "true");

    assessmentStartedRef.current = true;

    handleFullscreen();
    setInstructionsModal(false);
    InitializeAssessment();

    toast.info(
      "Timer and recording will get start once you get the question.",
      {
        position: "top-right",
        toastId: customId,
        autoClose: 4000,
      }
    );
  };

  let recordedChunks = []; // This variable is for storing the TEXT data inside the Startrecording function.
  let FullText = ""; // This variable is for storing the AUDIO Blob data inside the Startrecording function.

  const StartRecording = (roundId, questionId) => {

    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = true; // Collect final results only
    recognition.continuous = true;

    FullText = "";

    recognition.onresult = (event) => {
      let interimText = ""; // Temporary variable to hold the interim results

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          FullText += event.results[i][0].transcript + " ";
        } else {
          interimText += event.results[i][0].transcript;
        }
      }

      setConvertedText(FullText + interimText);
    };

    recordedChunks = [];

    recognition.start();

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.start();

      recorder.ondataavailable = (event) => {
        recordedChunks.push(event.data);
      };

      setTimeout(() => {
        recognition.stop();
        recorder.stop();

        recorder.onstop = () => {
          const audioBlob = new Blob(recordedChunks, { type: "audio/wav" });
          if (!cheatingStateRef.current) {
            SubmitAnswer(roundId, audioBlob, questionId, FullText); // This is for the auto submitting the answer.
          }
        };
      }, TimeLimit * 1000);
    });
  };

  const SubmitAnswer = (roundId, audioBlobFile, questionId, textFile) => {
    setStartTimer(false);
    setShowDna(false);
    setMarksObtainedLoader(true);

    toast.success(
      "The response is submitted and is currently being processed.",
      {
        position: "top-right",
        toastId: customId,
        autoClose: 4000,
      }
    );

    const formdata = new FormData();
    formdata.append("round_id", roundId);
    formdata.append("audio_file", audioBlobFile, "recording.wav");
    formdata.append("question_id", questionId);
    formdata.append("answer_text", textFile);
    formdata.append("round_name", roundType);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${REACT_APP_AI_API_URL}/analyze-answer/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "0") {
          // setMarksObtained("0");
          localStorage.setItem("MarksObtained", "0")
        } else if (result.status == "1") {
          // setMarksObtained(result.result.split(" ")[1].replace(".", ""));
          localStorage.setItem("MarksObtained", result.result.split(" ")[1].replace(".", ""))
        }
        assessmentStartedRef.current = false;
        localStorage.setItem("AssessmentStarted", "done");
        setMarksObtainedLoader(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      // for full screen
      if (!document.fullscreenElement && isFullscreen) {
        if (assessmentStartedRef.current) {
          //   exitFullscreen();
          AutoSubmitAnswer();
        }
      }
      setIsFullscreen(!!document.fullscreenElement); // Update fullscreen status
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [isFullscreen]);

  useEffect(() => {
    // for tab change
    const handleBlur = () => {
      if (assessmentStartedRef.current) {
        AutoSubmitAnswer();
      }
    };
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const AutoSubmitAnswer = () => {
    cheatingStateRef.current = true;
    // setShowCheatingMarksModal(true)
    // setMarksObtained("0");
    localStorage.setItem("MarksObtained", "0")
    setStartTimer(false);


    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error("Error attempting to exit fullscreen mode:", err);
      });
    }
  };

  const RoundComplete = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      vacancy_uuid: VacancyIdForStartAssessment,
      round_no: roundNo,
      round_type: roundType,
      round_marks: MarksObtained,
      round_status: "true",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/assessment_detail`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          navigate("/applied_vacancy");
          localStorage.setItem("AssessmentStarted", "false");
          localStorage.removeItem("MarksObtained");
        }
      });
  };

  return (
    <>
      <div className="ExtemporeRound" ref={componentRef}>
        <KeyboardEventHandler
          handleKeys={["F11", "esc", "ctrl+tab", "alt+tab", "meta"]}
          isDisabled={true}
          onKeyEvent={(key, e) => {
            e.preventDefault();
          }}
        />

        {
          ShowMainPageLoader &&
          <div className="MainPageLoader" >
            <div className="LoaderContent" >
              <Hourglass visible={true} height="50" width="50" colors={['#306cce', '#72a1ed']} />
              <p style={{ color: 'black', fontWeight: '500' }}>Loading ...</p>
            </div>
          </div>
        }

        <img src={StarteleName} id="Logo" />

        <div className="RoundInfo">
          <div className="RoundInfoLeft">
            <h2>Round Name : This is extempore Round</h2>
            <h3>Difficulty Level : Level {difficultyLevel} </h3>
            <p>Round : 1 / 4 </p>
          </div>

          <div id="trialBox">
            <CountdownCircleTimer
              isPlaying={StartTimer}
              fill={"yellow"}
              duration={TimeLimit}
              colors={["#9ef01a", "#c6db34", "#f7b801", "#f8961e", "#dd1c1a"]}
              colorsTime={[
                TimeLimit,
                TimeLimit * 0.75,
                TimeLimit * 0.5,
                TimeLimit * 0.25,
                0,
              ]}
              size={140}
              strokeWidth={15}
              trailStrokeWidth={10}
              trailColor={"#7AB4F566"}
            // onComplete={HandleOnTimerStop}
            >
              {({ remainingTime }) => {
                const minutes = Math.floor(remainingTime / 60);
                const seconds = remainingTime % 60;

                return (
                  <div style={{ color: "white", fontSize: "25px" }}>
                    {minutes} : {seconds < 10 ? `0${seconds}` : seconds}
                  </div>
                );
              }}
            </CountdownCircleTimer>
          </div>
        </div>

        <div className="Section_1">
          <div className="Box_1">
            <h1>
              Ques.
              {questionText ? (
                questionText
              ) : (
                <Bars visible={true} height="30" width="30" />
              )}
            </h1>

            <div
              id="trialBox"
              style={{ animationName: "none", marginTop: "20px" }}
            >
              <button id="trial">
                {showDna && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: ".9rem",
                        marginTop: "5px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Recording <br /> Started{" "}
                    </p>
                    <DNA
                      visible={true}
                      height="60"
                      width="60"
                      ariaLabel="drenderna-loading"
                      wrapperClass="dna-wrapper"
                    />
                  </div>
                )}
              </button>
            </div>

            <p>
              {" "}
              Note : This is the question for the extempore. The timer has
              started at the top right corner. Please adhere to the timing. The
              response will be automatically submitted once the timer ends.
            </p>
          </div>

          <div className="Box_2">
            <OutlinedInput
              multiline
              rows={10} // Adjust the number of rows as needed
              type="text"
              value={convertedText}
              // disabled
              style={{
                width: "95%",
                fontSize: "20px",
                backgroundColor: "white",
              }}
              placeholder="Say your answer once the recording gets start , your answer will be visible here. ( But it is not editable !! )"
            />

            {MarksObtainedLoader &&
              <p style={{ color: 'white', fontWeight: '400', fontSize: '20px' }}> Loading result ...</p>
            }

            {MarksObtained && (
              <button id="SubmitButton" onClick={exitFullscreen}>
                View Result
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Modal for instructions before assessment  */}
      <Modal open={InstructionsModal}>
        <Box sx={ModalStyle2} className="ExtemporeRoundModal">
          <div>
            <h3 style={{ color: "#4B64FF" }}>
              {" "}
              Important Instructions for Extempore Round.{" "}
            </h3>

            <p style={{ color: "grey" }}>
              {" "}
              Please go through these important instructions carefully before
              attempting the assessment .
            </p>
          </div>

          <ul>
            <li>
              <p>
                <WarningAmber id="TickIcon" /> Please do not refresh the page
                once the assessment has started leading to the auto-submission of the assessment.
              </p>
            </li>
            <li>
              <p>
                <WarningAmber id="TickIcon" /> Please do not switch tabs or
                windows while completing the assessment otherwise the assessment will get auto-submit.
              </p>
            </li>
            <li>
              <p>
                <WarningAmber id="TickIcon" /> Please ensure that your device's
                microphone is functioning properly before attempting the
                assessment.
              </p>
            </li>

            <li>
              <p>
                <WarningAmber id="TickIcon" />
                Upon clicking the 'START' button below, both the timer and
                recording will begin. Speak your response clearly and properly
                into your microphone until the timer stops.
              </p>
            </li>
            <li>
              <p>
                <WarningAmber id="TickIcon" /> The timeline for this round will
                be displayed at the top of the assessment page as a Timer.
                Please adhere to the specified timings.
              </p>
            </li>
            <li>
              <p>
                <WarningAmber id="TickIcon" /> After completing the assessment
                you will be redirected to the applied jobs page.
              </p>
            </li>
          </ul>

          <div>
            <Button
              className="Button_1"
              variant="contained"
              style={{ margin: "0px 10px" }}
              onClick={StartAssessment}
            >
              Start
            </Button>

            <Button
              onClick={() => navigate("/applied_vacancy")}
              style={{ color: "indianRed" }}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Modal for already completed assessment  */}
      <Modal open={CompletedExamModal}>
        <Box
          sx={ModalStyle2}
          className="ExtemporeRoundModal"
          style={{ width: "50%", padding: "25px 20px" }}
        >
          <h3 style={{ color: "#4B64FF" }}>
            Assessment Already Completed
          </h3>

          <p style={{ color: "grey", textAlign: "center" }}>
            You have already given this round of your assessment.
          </p>

          <center>
            <img src={CompletedGif} style={{
              width: "300px",
              height: "auto"
            }} />
          </center>

          <Button
            className="Button_1"
            variant="contained"
            style={{ margin: "0px auto", transform: "scale(1.2)" }}
            onClick={() => navigate("/applied_vacancy")}
          >
            Go to Applied Vacancies
          </Button>
        </Box>
      </Modal>

      <Modal open={ShowMarksModal}>
        <Box
          sx={ModalStyle2}
          className="ExtemporeRoundModal"
          style={{ width: "50%", padding: "25px 20px" }}
        >
          <h3 style={{ color: "#4B64FF" }}>
            {" "}
            Assessment Submitted Successfully
          </h3>

          <p style={{ color: "grey", textAlign: "center" }}>
            This is your result for this assessment. You can view your marks on "Applied Vacancies" page once you complete all the remaining rounds. The final result will be sent to your email followed by the further asseessment round if qualified.
          </p>

          <center>
            {MarksObtained ? (
              <h1
                id="MarksObtained"
                style={{
                  color: MarksObtained < 50 ? "indianred" : "#4B64FF",
                  border:
                    MarksObtained < 50
                      ? "2px indianred solid"
                      : "2px #4B64FF solid",
                }}
              >
                {MarksObtained < 50
                  ? "You didn't clear this round."
                  : "You cleared this round."}
              </h1>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Bars visible={true} height="60" width="80" color="#9c88ff" />
              </div>
            )}
          </center>

          <Button
            className="Button_1"
            variant="contained"
            style={{ margin: "0px auto", transform: "scale(1.2)" }}
            onClick={RoundComplete}
          >
            Complete
          </Button>
        </Box>
      </Modal>

      <Modal open={cheatingStateRef.current}>
        <Box
          sx={ModalStyle2}
          className="ExtemporeRoundModal"
          style={{ width: "50%", padding: "25px 20px" }}
        >
          <h3 style={{ color: "indianred" }}> Assessment Submitted</h3>

          <p style={{ color: "indianred", textAlign: "center" }}>
            Since you attempted to refresh page , switch tabs or exit full-screen mode, which
            violates the assessment guidelines outlined in the instructions,
            your assessment has been submitted, and this is your result for this assessment.
          </p>

          <center>
            {MarksObtained ? (
              <h1
                id="MarksObtained"
                style={{ color: "indianred", border: "2px indianred solid" }}
              >
                You didn't clear this round.
              </h1>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Bars visible={true} height="60" width="80" color="#9c88ff" />
              </div>
            )}
          </center>

          <Button
            className="Button_1"
            variant="contained"
            style={{ margin: "0px auto", transform: "scale(1.2)" }}
            onClick={RoundComplete}
          >
            Complete
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ExtemporeRound;
