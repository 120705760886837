import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Login from './Components/Sessions/Login/Login';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import { Navigate, Link, useLocation } from 'react-router-dom';
import SignUp from './Components/Sessions/SignUp/SignUp';
import { useEffect } from 'react';
import ForgotPassword from './Components/Sessions/Forgot Password/ForgotPassword';
import { ToastContainer, } from "react-toastify";
import HomePage from './Components/Home Pages/HomePage';
import ProfilePage from './Components/Profile Pages/ProfilePage';
import AllVacancies from './Components/EnterprisePages/AllVacancies/AllVacancies';
import CreateVacancy from './Components/EnterprisePages/CreateVacancy/CreateVacancy';
import EditVacancy from './Components/EnterprisePages/CreateVacancy/EditVacancy';
import VacancyPage from './Components/EnterprisePages/ShowVacancy/VacancyPage';
import AppliedVacancyPage from './Components/SeekerPages/AppliedVacancyPage/AppliedVacancyPage';
import ExtemporeRound from './Components/InterviewScreens/Extempore/ExtemporeRound';
import CodingRound from './Components/InterviewScreens/CodingRound/CodingRound';
import Footer from './Components/Footer/Footer';
import { AuthProvider, useAuth } from './AuthContext';
import ScrollTop from './Components/ScrollTop';
import QuizRound from './Components/InterviewScreens/QuizRound/QuizRound';
import ComprehensionRound from './Components/InterviewScreens/ComprehensionRound/ComprehensionRound';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import EnterpriseProfilePage from './Components/Profile Pages/EnterpriseProfilePage';
import TotalEnterprises from './Components/AdminPages/TotalEnterprises/TotalEnterprises';
import EnabledEnterprises from './Components/AdminPages/Enabled Enterprises/EnabledEnterprises';
import DisabledEnterprises from './Components/AdminPages/DisabledEnterprises/DisabledEnterprises';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useAuth();
    return isLoggedIn ? children : <Navigate to="/login" />;
  };

  const RedirectIfLoggedIn = ({ children }) => {
    const { isLoggedIn } = useAuth();
    return isLoggedIn ? <Navigate to="/homePage" /> : children;
  };

  return (


    <div className="App">
      <>
        <Router>
          <ScrollTop>
            <AuthProvider>
              <Navbar />
              <ToastContainer />
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<RedirectIfLoggedIn><Login /></RedirectIfLoggedIn>} />
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/homePage" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/profilePage" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                <Route path="/company_profilePage" element={<ProtectedRoute><EnterpriseProfilePage /></ProtectedRoute>} />
                <Route path="/all_vacancies" element={<ProtectedRoute><AllVacancies /></ProtectedRoute>} />
                <Route path="/create_vacancy" element={<ProtectedRoute><CreateVacancy /></ProtectedRoute>} />
                <Route path="/edit_vacancy" element={<ProtectedRoute><EditVacancy /></ProtectedRoute>} />
                <Route path="/vacancy_page" element={<ProtectedRoute><VacancyPage /></ProtectedRoute>} />
                <Route path="/applied_vacancy" element={<ProtectedRoute><AppliedVacancyPage /></ProtectedRoute>} />
                <Route path="/extempore_round" element={<ProtectedRoute><ExtemporeRound /></ProtectedRoute>} />
                <Route path="/coding_round" element={<ProtectedRoute><CodingRound /></ProtectedRoute>} />
                <Route path="/quiz_round" element={<ProtectedRoute><QuizRound /></ProtectedRoute>} />
                <Route path="/comprehension_round" element={<ProtectedRoute><ComprehensionRound /></ProtectedRoute>} />

                {/* ADMIN PAGES */}
                <Route path="/total_enterprises" element={<ProtectedRoute><TotalEnterprises /></ProtectedRoute>} />
                <Route path="/enabled_enterprises" element={<ProtectedRoute><EnabledEnterprises /></ProtectedRoute>} />
                <Route path="/disabled_enterprises" element={<ProtectedRoute><DisabledEnterprises /></ProtectedRoute>} />

                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
              <Footer />
            </AuthProvider>
          </ScrollTop>
        </Router>
      </>
    </div>
  );
}

export default App;
