import React, { useEffect } from 'react';
import './AdminHomePage.css'
import { H1, H2 } from '../Typography';
import Avatar from '../../Assets/Avtar.png'
import { Button, ToggleButton, Tooltip, Select, MenuItem } from '@mui/material';
import { Bookmark, BookmarkBorderOutlined, BusinessCenterOutlined, BusinessOutlined, GpsFixed, HouseRounded, LocationOn, LocationOnOutlined, QueryStatsRounded, WorkHistoryRounded, WorkOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/StarteleLogo.png';
import Card_1 from '../../Assets/Card_1.png'
import Card_2 from '../../Assets/Card_2.png'
import Card_3 from '../../Assets/Card_3.png'
import Card_4 from '../../Assets/Card_4.png'
import { useState } from 'react';
import ReactEcharts from "echarts-for-react";
import { Bars, Hourglass } from 'react-loader-spinner';

const AdminHomePage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const DomainUuid = localStorage.getItem('DomainUuid')

    const [TotalEnterprises, setTotalEnterprises] = useState("");
    const [EnabledEnterprises, setEnabledEnterprises] = useState("");
    const [DisabledEnterprises, setDisabledEnterprises] = useState("");

    const GetAdminDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "domain_uuid": DomainUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/get_admin`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setTotalEnterprises(result.admin_count.total_enterprise)
                setEnabledEnterprises(result.admin_count.enable_enterprise)
                setDisabledEnterprises(result.admin_count.disable_enterprise)
            })
    }

    useEffect(() => {
        GetAdminDetails()
    }, [])

    return (
        <>
            <div className='AdminHomePage' >
                <div className='profile_section'>
                    <div className='Profile_left' >
                        <div id="one">
                            <div id="welcome">
                                <h2>Welcome to <span> Hire Wiz</span> </h2>
                                <p> Introducing our AI-powered interview platform, meticulously designed to revolutionize the hiring process for enterprises. Enhance your recruitment efficiency and precision with our intelligent assessment tools. Experience seamless, efficient, and effective hiring, and connect with a vast network of talented professionals, ensuring you find the perfect fit for your esteemed organization. </p>
                            </div>
                        </div>

                        <div id="two">
                            <h2>Analytics <span><QueryStatsRounded style={{ fontSize: '34px' }} /> </span> </h2>

                            <div className="cardBox">
                                <div className="card" onClick={() => navigate('/total_enterprises')} >
                                    {/* <img src={Card_1} /> */}
                                    <h2>{TotalEnterprises}</h2>
                                    <h3>Total Enterprises</h3>
                                    <p>Click to view the list of all enterprises. </p>
                                </div>

                                <div className="card" onClick={() => navigate('/enabled_enterprises')} >
                                    {/* <img src={Card_3} /> */}
                                    <h2>{EnabledEnterprises}</h2>
                                    <h3>Enabled Enterprises</h3>
                                    <p>Click to view the list of enabled enterprises. </p>
                                </div>

                                <div className="card" onClick={() => navigate('/disabled_enterprises')} >
                                    {/* <img src={Card_4} /> */}
                                    <h2>{DisabledEnterprises}</h2>
                                    <h3>Disabled Enterprises</h3>
                                    <p>Click to view the list of disabled enterprises. </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='Enterprise_graph'>
                            <h2>Profile Visits <span><QueryStatsRounded style={{ fontSize: '34px' }} /> </span> </h2>

                            <ReactEcharts
                                style={{ height: '90vh', width: '100%' }}
                                option={getOption()}
                                opts={{ renderer: "svg" }}
                            />

                            <div className='Enterprise_date_select'>
                                <Select
                                    value={SelectedYear}
                                    onChange={handleYear}
                                    displayEmpty
                                    style={{ height: "30px", width: "10%", margin: "10px 10px" }}
                                    MenuProps={{
                                        getcontentanchorel: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="0" disabled>
                                        Select a year
                                    </MenuItem>
                                    {
                                        yearsArray.map((year, index) => {
                                            return <MenuItem key={index} value={year}>{year}</MenuItem>
                                        })
                                    }
                                </Select>
                            </div>
                        </div> */}
                    </div>

                    <div className='Profile_right' >
                        <div id='one' >
                            <div id="profile">
                                <div id='ProfileImage'>
                                    <img src={Logo} />
                                </div>
                            </div>
                            <h2>NAME HERE</h2>
                            <p>COMPANY HERE </p>
                            <p> <LocationOn id="icon" />
                                LOCATION HERE
                            </p>
                            <Button variant="contained"
                                onClick={() => navigate('/company_profilePage')}
                                style={{
                                    fontSize: '15px',
                                    width: '50%',
                                    color: 'white',
                                    border: "1px solid #4B64FF",
                                    backgroundColor: '#4B64FF',
                                    border: '0px ',
                                    marginTop: '10px'
                                }}
                            >
                                View More
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminHomePage
