import React, { useState } from 'react';
import './EnterpriseProfilePage.css';

import Logo from '../../Assets/StarteleLogo.png';
import CoverPhoto from '../../Assets/EnterpriseCoverPhoto.jpg';

import { H1, H2 } from '../Typography';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import PhoneInput from "react-phone-input-2";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Add, AddRounded, BusinessRounded, Call, ContactMailRounded, CreateRounded, Delete, DeleteOutline, Edit, Email, GpsFixed, Phone } from '@mui/icons-material';
import { Box, Button, FormControl, MenuItem, Modal, OutlinedInput, Select, useMediaQuery } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useFetcher, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { red } from '@mui/material/colors';

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: 'none',
};

const EnterpriseProfilePage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const CheckProfileId = localStorage.getItem("CheckProfileId");
    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID");
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");


    const CurrentDate = new Date()
    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1950, currentYear);
    const MonthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const [OpenEditEnterpriseModal, setOpenEditEnterpriseModal] = useState(false)

    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            setOpenEditEnterpriseModal(false);
        } else {
            setOpenEditEnterpriseModal(true);
        }
    }, []);

    const [EnterpriseName, setEnterpriseName] = useState(localStorage.getItem('CompanyName'))
    const [EnterpriseTagLine, setEnterpriseTagLine] = useState("")
    const [EnterpriseEstablishmentYear, setEnterpriseEstablishmentYear] = useState("")
    const [EnterpriseCinNumber, setEnterpriseCinNumber] = useState("")
    const [EnterpriseAddress, setEnterpriseAddress] = useState("")
    const [EnterprisePincode, setEnterprisePincode] = useState("")
    const [EnterpriseCity, setEnterpriseCity] = useState("")
    const [EnterpriseState, setEnterpriseState] = useState("")
    const [EnterpriseCountry, setEnterpriseCountry] = useState("")
    const [EnterpriseAboutUs, setEnterpriseAboutUs] = useState("")
    const [EnterpriseType, setEnterpriseType] = useState("")
    const [EnterpriseWebsiteLink, setEnterpriseWebsiteLink] = useState("")
    const [EnterpriseMobileNumbers, setEnterpriseMobileNumbers] = useState("")
    const [EnterpriseEmailId, setEnterpriseEmailId] = useState("")

    // error message state 
    const [EnterPriseNameError, setEnterPriseNameError] = useState('');
    const [EnterPriseTypeError, setEnterPriseTypeError] = useState('');
    const [EnterPriseMobNumError, setEnterPriseMobNumError] = useState('');
    const [EnterPriseEmailError, setEnterPriseEmailError] = useState('');
    const [EnterPriseEmailFlag, setEnterPriseEmailFlag] = useState(true)
    const [EnterPriseCountryError, setEnterPriseCountryError] = useState('');
    const [EnterPriseStateError, setEnterPriseStateError] = useState('');
    const [EnterPriseCityError, setEnterPriseCityError] = useState('');
    const [EnterPriseCompanyAddressError, setEnterPriseCompanyAddressError] = useState('');
    const [EnterPrisePincodeError, setEnterPrisePinCodeError] = useState('');
    const [EnterpriseEstablishmentYearError, setEnterpriseEstablishmentYearError] = useState('');
    const [EnterPriseAboutusError, setEnterPriseAboutusError] = useState('');
    const [EnterPriseWebsiteLinkError, setEnterPriseWebsiteLinkError] = useState('');



    const AddressForGoogleMap = EnterpriseAddress + EnterpriseCity + EnterpriseState + EnterpriseCountry
    const SrcForGoogleMap = `https://maps.google.com/maps?&q="+${AddressForGoogleMap}"&output=embed`;

    // country state city code start here 
    const [AllStates, setAllStates] = useState([]);
    const [statesMap, setStatesMap] = useState({});
    const [AllCities, setAllCities] = useState([]);

    const countries = Country.getAllCountries();

    const countryMap = countries.reduce((acc, country) => {
        acc[country.name] = country.isoCode;
        return acc;
    }, {});

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        ...country
    }));

    const getStates = (countryIsoCode) => {
        const stateList = State.getStatesOfCountry(countryIsoCode);

        const statesMap = stateList.reduce((acc, state) => {
            acc[state.name] = state.isoCode;
            return acc;
        }, {});

        setStatesMap(statesMap);

        const mappedStates = stateList.map((state) => ({
            label: state.name,
            value: state.name,
            isoCode: state.isoCode,
            ...state
        }));
        setAllStates(mappedStates);

        return mappedStates;
    };

    // Updating the selected state value 
    useEffect(() => {
        if (EnterpriseCountry) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            getStates(countryIsoCode);
        }
    }, [EnterpriseCountry]);

    // Geeting the all cities name based on selected state
    const getCities = (countryIsoCode, stateIsoCode) => {
        const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

        const mappedCities = cityList.map((city) => ({
            label: city.name,
            value: city.name,
            ...city
        }));
        setAllCities(mappedCities);

        return mappedCities;
    };

    // Updating the cities name in the city selectbox  
    useEffect(() => {
        if (EnterpriseState) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            const stateIsoCode = statesMap[EnterpriseState];
            getCities(countryIsoCode, stateIsoCode);
        }
    }, [EnterpriseState, statesMap]);

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setEnterpriseName(result.enterprises[0].company_name)
                setEnterpriseTagLine(result.enterprises[0].company_tag_line)
                setEnterpriseEstablishmentYear(result.enterprises[0].year_of_establishment)
                setEnterpriseCinNumber(result.enterprises[0].cin_number)
                setEnterpriseAddress(result.enterprises[0].company_address)
                setEnterprisePincode(result.enterprises[0].company_pincode)
                setEnterpriseCity(result.enterprises[0].company_city)
                setEnterpriseState(result.enterprises[0].company_state)
                setEnterpriseCountry(result.enterprises[0].company_country)
                setEnterpriseAboutUs(result.enterprises[0].company_about)
                setEnterpriseType(result.enterprises[0].company_type)
                setEnterpriseWebsiteLink(result.enterprises[0].company_website)
                setEnterpriseMobileNumbers(result.enterprises[0].company_contact_number)
                setEnterpriseEmailId(result.enterprises[0].company_email_id)
            })
    }

    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            ViewEnterpriseDetails()
        }
    }, [OpenEditEnterpriseModal]);


    const HandleCreateEnterprise = () => {


        if (!EnterpriseName) {
            setEnterPriseNameError('Please enter company name ');
        } else {
            setEnterPriseNameError('');
        }
        if (!EnterpriseType) {
            setEnterPriseTypeError('Please enter company type ')
        } else {
            setEnterPriseTypeError('');
        }
        if (!EnterpriseEmailId) {
            setEnterPriseEmailError('Please enter your email id')
        } else {
            setEnterPriseEmailError('')
        }
        if (!EnterpriseMobileNumbers) {
            setEnterPriseMobNumError('Please enter contact number')
        } else {
            setEnterPriseMobNumError('')
        }

        if (EnterpriseCountry == 0) {
            setEnterPriseCountryError('Please select your country')
        } else {
            setEnterPriseCountryError('')
        }
        if (EnterpriseState == 0) {
            setEnterPriseStateError('Please select your state ');
        } else {
            setEnterPriseStateError("");
        }
        if (EnterpriseCity == 0) {
            setEnterPriseCityError('Please select your city');
        } else {
            setEnterPriseCityError('')
        }
        if (!EnterpriseAddress) {
            setEnterPriseCompanyAddressError('Please enter company address');
        } else {
            setEnterPriseCompanyAddressError('');
        }
        if (!EnterprisePincode) {
            setEnterPrisePinCodeError('Please enter your pincode')
        } else {
            setEnterPrisePinCodeError('')
        }
        if (EnterpriseEstablishmentYear == 0) {
            setEnterpriseEstablishmentYearError('Please select the year the company was established');
        } else {
            setEnterpriseEstablishmentYearError('')
        }
        if (!EnterpriseAboutUs) {
            setEnterPriseAboutusError('Please provide information about your company')
        } else {
            setEnterPriseAboutusError('');
        }
        if (!EnterpriseWebsiteLink) {
            setEnterPriseWebsiteLinkError('Please enter website link');

        } else {
            setEnterPriseWebsiteLinkError('')
        }


        if (
            !EnterpriseName ||
            !EnterpriseType ||
            !EnterpriseMobileNumbers ||
            !EnterpriseEmailId ||
            !EnterpriseAddress ||
            EnterpriseEstablishmentYear == 0 ||
            EnterpriseCountry == 0 ||
            EnterpriseState == 0 ||
            EnterpriseCity == 0 ||
            !EnterprisePincode ||
            !EnterpriseAboutUs ||
            !EnterpriseWebsiteLink
        ) {
            toast.warn('Please fill in all required fields', {
                toastId: customId,
                autoClose: 2000,
                position: 'top-right'
            })
            return;
        }

        if (!EnterPriseEmailFlag) {
            toast.warn("Please enter valid email address", {
                toastId: customId,
                autoClose: 2000,
                position: "top-right"
            })
            return;
        }

        if (EnterpriseMobileNumbers.length <= 11) {
            toast.warn("Please enter valid contact number", {
                toastId: customId,
                autoClose: 2000,
                position: "top-right"
            })
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "company_name": EnterpriseName,
            "company_tag_line": EnterpriseTagLine,
            "year_of_establishment": EnterpriseEstablishmentYear.toString(),
            "cin_number": EnterpriseCinNumber,
            "company_country": EnterpriseCountry,
            "company_state": EnterpriseState,
            "company_city": EnterpriseCity,
            "company_pincode": EnterprisePincode,
            "company_address": EnterpriseAddress,
            "company_about": EnterpriseAboutUs,
            "company_website": EnterpriseWebsiteLink,
            "company_email_id": EnterpriseEmailId,
            "company_contact_number": EnterpriseMobileNumbers,
            "company_type": EnterpriseType,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };


        fetch(`${REACT_APP_BASE_API_URL}/create_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    localStorage.setItem(
                        "CheckCompanyProfileID", "company_profile_uuid" in result.enterprises[0]
                    );
                    localStorage.removeItem('CompanyName')
                    setOpenEditEnterpriseModal(false);
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })

    }

    const HandleEditEnterprise = () => {




        if (!EnterpriseName) {
            setEnterPriseNameError('Please enter your company name ');
        } else {
            setEnterPriseNameError('');
        }
        if (!EnterpriseType) {
            setEnterPriseTypeError('Please enter your company type ')
        } else {
            setEnterPriseTypeError('');
        }
        if (!EnterpriseEmailId) {
            setEnterPriseEmailError('Please enter your email id')
        } else {
            setEnterPriseEmailError('')
        }
        if (!EnterpriseMobileNumbers) {
            setEnterPriseMobNumError('Please enter your contact number')
        } else {
            setEnterPriseMobNumError('')
        }
        if (EnterpriseCountry == 0) {
            setEnterPriseCountryError('Please select your country')
        } else {
            setEnterPriseCountryError('')
        }
        if (EnterpriseState == 0) {
            setEnterPriseStateError('Please select your state ');
        } else {
            setEnterPriseStateError("");
        }
        if (EnterpriseCity == 0) {
            setEnterPriseCityError('Please select your city');
        } else {
            setEnterPriseCityError('')
        }
        if (!EnterpriseAddress) {
            setEnterPriseCompanyAddressError('Please enter your company address');
        } else {
            setEnterPriseCompanyAddressError('');
        }
        if (!EnterprisePincode) {
            setEnterPrisePinCodeError('Please enter your pincode')
        } else {
            setEnterPrisePinCodeError('')
        }
        if (EnterpriseEstablishmentYear == 0) {
            setEnterpriseEstablishmentYearError('Please select the year the company was established');
        } else {
            setEnterpriseEstablishmentYearError('')
        }
        if (!EnterpriseAboutUs) {
            setEnterPriseAboutusError('Please provide information about your company')
        } else {
            setEnterPriseAboutusError('');
        }
        if (!EnterpriseWebsiteLink) {
            setEnterPriseWebsiteLinkError('Please enter your website link');

        } else {
            setEnterPriseWebsiteLinkError('')
        }

        if (
            !EnterpriseName ||
            !EnterpriseType ||
            !EnterpriseMobileNumbers ||
            !EnterpriseEmailId ||
            !EnterpriseAddress ||
            EnterpriseEstablishmentYear == 0 ||
            EnterpriseCountry == 0 ||
            EnterpriseState == 0 ||
            EnterpriseCity == 0 ||
            !EnterprisePincode ||
            !EnterpriseAboutUs ||
            !EnterpriseWebsiteLink
        ) {
            toast.warn('Please fill in all required fields', {
                toastId: customId,
                autoClose: 2000,
                position: 'top-right'
            })
            return;
        }

        if (!EnterPriseEmailFlag) {
            toast.warn("Please enter valid email address", {
                toastId: customId,
                autoClose: 2000,
                position: "top-right"
            })
            return;
        }

        if (EnterpriseMobileNumbers.length <= 11) {
            toast.warn("Please enter valid contact number", {
                toastId: customId,
                autoClose: 2000,
                position: "top-right"
            })
            return;
        }



        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "company_name": EnterpriseName,
            "company_tag_line": EnterpriseTagLine,
            "year_of_establishment": EnterpriseEstablishmentYear.toString(),
            "cin_number": EnterpriseCinNumber,
            "company_country": EnterpriseCountry,
            "company_state": EnterpriseState,
            "company_city": EnterpriseCity,
            "company_pincode": EnterprisePincode,
            "company_address": EnterpriseAddress,
            "company_about": EnterpriseAboutUs,
            "company_website": EnterpriseWebsiteLink,
            "company_email_id": EnterpriseEmailId,
            "company_contact_number": EnterpriseMobileNumbers,
            "company_type": EnterpriseType,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/edit_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    setOpenEditEnterpriseModal(false);
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    const HandleCancelButton = () => {
        if (CheckCompanyProfileID == "true") {
            setOpenEditEnterpriseModal(false);
            setEnterpriseName("")
            setEnterpriseTagLine("")
            setEnterpriseEstablishmentYear("")
            setEnterpriseCinNumber("")
            setEnterpriseAddress("")
            setEnterprisePincode("")
            setEnterpriseCity("")
            setEnterpriseState("")
            setEnterpriseCountry("")
            setEnterpriseAboutUs("")
            setEnterpriseType("")
            setEnterpriseWebsiteLink("")
            setEnterpriseMobileNumbers("")
            setEnterpriseEmailId("")
            setEnterPriseNameError("")
            setEnterPriseTypeError("")
            setEnterPriseMobNumError("")
            setEnterPriseEmailError("")
            setEnterPriseCountryError("")
            setEnterPriseStateError("")
            setEnterPriseCityError("")
            setEnterPriseCompanyAddressError("")
            setEnterPrisePinCodeError("")
            setEnterpriseEstablishmentYearError("")
            setEnterPriseAboutusError("")
            setEnterPriseWebsiteLinkError("")
        } else if (CheckCompanyProfileID == "false") {
            navigate("/homePage");
            setOpenEditEnterpriseModal(false);
            setEnterpriseName("")
            setEnterpriseTagLine("")
            setEnterpriseEstablishmentYear("")
            setEnterpriseCinNumber("")
            setEnterpriseAddress("")
            setEnterprisePincode("")
            setEnterpriseCity("")
            setEnterpriseState("")
            setEnterpriseCountry("")
            setEnterpriseAboutUs("")
            setEnterpriseType("")
            setEnterpriseWebsiteLink("")
            setEnterpriseMobileNumbers("")
            setEnterpriseEmailId("")
            setEnterPriseNameError("")
            setEnterPriseTypeError("")
            setEnterPriseMobNumError("")
            setEnterPriseEmailError("")
            setEnterPriseCountryError("")
            setEnterPriseStateError("")
            setEnterPriseCityError("")
            setEnterPriseCompanyAddressError("")
            setEnterPrisePinCodeError("")
            setEnterpriseEstablishmentYearError("")
            setEnterPriseAboutusError("")
            setEnterPriseWebsiteLinkError("")
        }
    }

    // Validations start here for edit company details modal 

    const handleEnterPriseName = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "") // Allow alphanumeric and spaces

        if (inputValue.trim() === "") {
            setEnterPriseNameError('Please enter your company name');
        } else {
            setEnterPriseNameError('')
        }
        setEnterpriseName(inputValue)
    }

    const handleEnterPriseType = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");

        if (inputValue.trim() === "") {
            setEnterPriseTypeError('Please enter your company type')
        } else {
            setEnterPriseTypeError('')
        }
        setEnterpriseType(inputValue)
    }

    const handleEnterPriseEmail = (e) => {
        const EnterPriseEmail = e.target.value;
        setEnterpriseEmailId(EnterPriseEmail);
        ValidateEmail(EnterPriseEmail);
    };

    const ValidateEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setEnterPriseEmailError("Please enter your email id");
            setEnterPriseEmailFlag(false)
        } else if (!emailPattern.test(email)) {
            setEnterPriseEmailError("Invalid email address");
            setEnterPriseEmailFlag(false)
            // return;
        } else {
            setEnterPriseEmailError(""); // Clear any previous error
            setEnterPriseEmailFlag(true)
        }
    };

    const handleEnterPriseCountry = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setEnterPriseCountryError('Please select your country');
        } else {
            setEnterPriseCountryError('');
            setEnterpriseCountry(inputValue);

        }
    }

    const handleEnterPriseState = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setEnterPriseStateError('Please select your state')
        } else {
            setEnterPriseStateError('');
            setEnterpriseState(inputValue)
        }

    }

    const handleEnterPriseCity = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setEnterPriseCityError('Please select your city')
        } else {
            setEnterPriseCityError('');
            setEnterpriseCity(inputValue);
        }

    }

    const handleEnterPriseCompanyAddress = (e) => {
        const inputValue = e.target.value;
        // const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9,\s]/g, "");

        if (inputValue.trim() === "") {
            setEnterPriseCompanyAddressError('Please enter your company address')
        } else {
            setEnterPriseCompanyAddressError('')
        }

        setEnterpriseAddress(inputValue)

    }

    const handleEnterPrisePinCode = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, "");

        if (inputValue.trim() === "") {
            setEnterPrisePinCodeError('Please enter your pincode')
        } else {
            setEnterPrisePinCodeError('')
        }

        setEnterprisePincode(numericValue);

    }

    const handleEnterPriseEstablishmentYear = (e) => {
        const inputValue = e.target.value;
        if (inputValue === 0 || inputValue === "") {
            setEnterpriseEstablishmentYearError('Please select the year the company was established')
        } else {
            setEnterpriseEstablishmentYearError('');
            setEnterpriseEstablishmentYear(inputValue)
        }

    }

    const handleEnterPriseAboutus = (e) => {
        const inputValue = e.target.value;

        // Update the input value even if the string is empty
        if (inputValue.trim() === "") {
            setEnterPriseAboutusError("Please provide information about your company.");
        } else {
            setEnterPriseAboutusError("");
        }

        // Replace any non-alphabetic and non-numeric characters, but only after setting the error
        const alphabeticValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");

        // Update the enterprise about us even if the value is empty
        setEnterpriseAboutUs(alphabeticValue);
    };


    const handleEnterPriseWebsiteLink = (e) => {
        const inputValue = e.target.value;

        // URL validation regex
        const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}(\/\S*)?$/;

        if (inputValue.trim() === "") {
            setEnterPriseWebsiteLinkError("Please enter your website link");
        } else if (!urlPattern.test(inputValue)) {
            setEnterPriseWebsiteLinkError("Please enter a valid website link (e.g., https://www.example.com)");
        } else {
            setEnterPriseWebsiteLinkError("");
            setEnterpriseWebsiteLink(inputValue);
        }
    };






    return (
        <>
            <div className='EnterpriseProfilePage' >
                <div className="section_1">
                    <div className="part1"
                        style={{ backgroundImage: `url(${CoverPhoto})` }}
                    >
                        <div className='one_icons' >
                            <Delete id="icon" />
                            <Edit id="icon" />
                        </div>
                    </div>

                    <div className="part2">
                        <div className="imageBox">
                            <img src={Logo} alt="" />
                        </div>
                    </div>

                    <div className="part3">
                        <div id="companyName">
                            <h2>
                                {EnterpriseName === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Company Name
                                </span> : EnterpriseName}
                            </h2>
                            <p>{EnterpriseTagLine === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Tag Line
                            </span> : EnterpriseTagLine}</p>
                            <p><Phone id="icon" />{
                                EnterpriseMobileNumbers.length == 0 ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Number
                                </span> : EnterpriseMobileNumbers}
                            </p>
                        </div>

                        <div id="companyAddress">


                            <a href="mailto:info@startelelogic.com"> <Email id="icon" />{EnterpriseEmailId === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Email Id
                            </span> : EnterpriseEmailId}</a>

                            <p> <GpsFixed id="icon" />
                                {EnterpriseAddress === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Address
                                </span> : EnterpriseAddress},

                                {EnterpriseCity === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add City
                                </span> : EnterpriseCity}

                                ({EnterprisePincode === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Pincode
                                </span> : EnterprisePincode})
                            </p>
                            <p>
                                {EnterpriseState === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add State
                                </span> : EnterpriseState}

                                ({EnterpriseCountry === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Country
                                </span> : EnterpriseCountry})
                            </p>
                        </div>
                    </div>
                </div>

                <div className="section_2">
                    <div className='part1' >
                        <h3>Company Details <CreateRounded id="icon" onClick={() => setOpenEditEnterpriseModal(true)} />  </h3>
                        <p>Welcome to your company information page. Below you will find comprehensive details about your company, including the official name, a brief overview of our history and about and your complete contact information. If not updated or provided , kindly update the information by clicking the edit button.
                        </p>
                    </div>

                    <div className='part2'>
                        <ul>
                            <li> <h2>Company Name </h2>  <p> {EnterpriseName === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Name
                            </span> : EnterpriseName} </p></li>

                            <li> <h2>Company's Tag Line </h2> <p> {EnterpriseTagLine === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Tag Line
                            </span> : EnterpriseTagLine} </p> </li>

                            <li><h2>Company Type  </h2> <p>{EnterpriseType === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Type
                            </span> : EnterpriseType} </p></li>

                            <li><h2>Contact Numbers  </h2> <p>{
                                EnterpriseMobileNumbers.length == 0 ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Number
                                </span> : EnterpriseMobileNumbers} </p>
                            </li>

                            <li><h2>Contact Email Id </h2> <p>{EnterpriseEmailId === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Email Id
                            </span> : EnterpriseEmailId}</p></li>



                            {/* <li> <h2>CIN Number </h2> <p> {EnterpriseCinNumber === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add CIN Number
                            </span> : EnterpriseCinNumber} </p> </li> */}

                            <div className='part2_1'>
                                <div className='addressList' >
                                    <li> <h2>Company's Address </h2><p> {EnterpriseAddress === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add Address
                                    </span> : EnterpriseAddress}</p> </li>

                                    <li> <h2>City & Pin code </h2><p>  {EnterpriseCity === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add City
                                    </span> : EnterpriseCity}
                                        , ( {EnterprisePincode === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                            PinCode
                                        </span> : EnterprisePincode} ) </p> </li>

                                    <li> <h2>State & Country </h2><p>  {EnterpriseState === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add State
                                    </span> : EnterpriseState}
                                        , ( {EnterpriseCountry === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                            Country
                                        </span> : EnterpriseCountry} ) </p> </li>
                                </div>

                                <iframe src={SrcForGoogleMap} id='GoogleMap' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <li> <h2>Year Of Establishment </h2> <p> {EnterpriseEstablishmentYear === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Establisment Year
                            </span> : EnterpriseEstablishmentYear} </p> </li>

                            <li><h2>About Us </h2><p> {EnterpriseAboutUs === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add About Us
                            </span> : EnterpriseAboutUs} </p></li>

                            <li><h2>CIN Number  </h2> <p> {EnterpriseCinNumber === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add CIN Number
                            </span> : EnterpriseCinNumber} </p> </li>



                            <li><h2>Company's Website </h2>
                                {
                                    EnterpriseWebsiteLink === "" ? <p><span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add Website's Link
                                    </span> </p> :
                                        <a href={EnterpriseWebsiteLink} target='_blank' >Click to view the website</a>
                                }
                            </li>


                        </ul>
                    </div>
                </div>
            </div >

            {/* MODALS START FROM HERE  */}
            {/* Modal for opening and editing enterprise details  */}
            <Modal
                open={OpenEditEnterpriseModal}
            >
                <Box sx={ModalStyle2} className='EnterpriseProfilePageModals' >
                    <CloseIcon
                        onClick={() => {
                            if (CheckCompanyProfileID == "true") {
                                setOpenEditEnterpriseModal(false);
                                setEnterpriseName("")
                                setEnterpriseTagLine("")
                                setEnterpriseEstablishmentYear("")
                                setEnterpriseCinNumber("")
                                setEnterpriseAddress("")
                                setEnterprisePincode("")
                                setEnterpriseCity("")
                                setEnterpriseState("")
                                setEnterpriseCountry("")
                                setEnterpriseAboutUs("")
                                setEnterpriseType("")
                                setEnterpriseWebsiteLink("")
                                setEnterpriseMobileNumbers("")
                                setEnterpriseEmailId("")
                                setEnterPriseNameError("")
                                setEnterPriseTypeError("")
                                setEnterPriseMobNumError("")
                                setEnterPriseEmailError("")
                                setEnterPriseCountryError("")
                                setEnterPriseStateError("")
                                setEnterPriseCityError("")
                                setEnterPriseCompanyAddressError("")
                                setEnterPrisePinCodeError("")
                                setEnterpriseEstablishmentYearError("")
                                setEnterPriseAboutusError("")
                                setEnterPriseWebsiteLinkError("")
                            } else if (CheckCompanyProfileID == "false") {
                                navigate("/homePage");
                                setOpenEditEnterpriseModal(false);
                                setEnterpriseName("")
                                setEnterpriseTagLine("")
                                setEnterpriseEstablishmentYear("")
                                setEnterpriseCinNumber("")
                                setEnterpriseAddress("")
                                setEnterprisePincode("")
                                setEnterpriseCity("")
                                setEnterpriseState("")
                                setEnterpriseCountry("")
                                setEnterpriseAboutUs("")
                                setEnterpriseType("")
                                setEnterpriseWebsiteLink("")
                                setEnterpriseMobileNumbers("")
                                setEnterpriseEmailId("")
                                setEnterPriseNameError("")
                                setEnterPriseTypeError("")
                                setEnterPriseMobNumError("")
                                setEnterPriseEmailError("")
                                setEnterPriseCountryError("")
                                setEnterPriseStateError("")
                                setEnterPriseCityError("")
                                setEnterPriseCompanyAddressError("")
                                setEnterPrisePinCodeError("")
                                setEnterpriseEstablishmentYearError("")
                                setEnterPriseAboutusError("")
                                setEnterPriseWebsiteLinkError("")
                            }
                        }}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "15px",
                            color: "black",
                            fontSize: "35px",
                        }}
                    />
                    <>
                        <h2>
                            {
                                CheckCompanyProfileID == "true" ? "Edit Company Details" : "Add Company Details"
                            }
                        </h2>

                        <p style={{ color: 'grey' }} > Here are the essential details that will enable the job seekers to connect with you and understand more about you.</p>

                        <div id='Type1' >
                            <h3>Company Name <span style={{ color: 'red' }} > *</span></h3>
                            <p>Please enter the full name of your company here.</p>
                            <input
                                type="text"
                                placeholder='Enter name here'
                                value={EnterpriseName}
                                tabIndex={1}
                                // onChange={(e) => setEnterpriseName(e.target.value)}
                                onChange={handleEnterPriseName}
                            />
                            {EnterPriseNameError &&
                                <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseNameError}</p>
                            }
                        </div>

                        <div id='Type1' >
                            <h3>Company's Tag Line </h3>
                            <p>Please enter the tagline for the company.</p>
                            <input
                                type="text"
                                placeholder='Enter tag-line here'
                                tabIndex={2}
                                value={EnterpriseTagLine}
                                onChange={(e) => setEnterpriseTagLine(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Company Type <span style={{ color: 'red' }} > *</span></h3>
                            <p>Please provide the company type for eg: IT Firm </p>
                            <input
                                type="text"
                                placeholder='Enter here for eg : IT Firm'
                                value={EnterpriseType}
                                tabIndex={3}
                                // onChange={(e) => setEnterpriseType(e.target.value)}
                                onChange={handleEnterPriseType}
                            />
                            {EnterPriseTypeError &&
                                <p style={{ color: 'red', margin: '5px 0px' }} >{EnterPriseTypeError}</p>
                            }
                        </div>

                        <div>
                            <h3 style={{
                                fontSize: "17px",
                                color: "#4B64FF",
                                fontWeight: "400",
                                marginBottom: '5px'
                            }} >Contact Number <span style={{ color: 'red' }}> *</span></h3>

                            <p style={{
                                fontSize: "13px",
                                color: "grey",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "4px"
                            }}   >You will be contacted via the given contact number. </p>


                            <>
                                <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    inputProps={{ tabIndex: "4" }}
                                    value={EnterpriseMobileNumbers}
                                    onChange={(mobile) => setEnterpriseMobileNumbers(mobile)}
                                    style={{
                                        width: "100%",
                                    }}
                                    inputStyle={{
                                        background: "transparent",
                                        width: "100%",
                                        height: '54px'
                                    }}
                                />
                                {EnterPriseMobNumError && (
                                    <p style={{ color: "red", fontSize: "13px", margin: '5px 0px' }}>{EnterPriseMobNumError}</p>
                                )}

                            </>

                        </div>

                        <div id='Type1' >
                            <h3>E Mail Id <span style={{ color: 'red' }}> *</span></h3>
                            <p>Please provide the official Email ID of the company for contact purposes.</p>
                            <input
                                type="text"
                                placeholder='Enter the Email-Id here.'
                                value={EnterpriseEmailId}
                                // onChange={(e) => setEnterpriseEmailId(e.target.value)}
                                onChange={handleEnterPriseEmail}
                                tabIndex={5}
                            />
                            {EnterPriseEmailError &&
                                <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseEmailError}</p>
                            }
                        </div>

                        <div id='Type1'>
                            <h3>Address Details <span style={{ color: 'red' }}> *</span></h3>
                            <p>Please fill up these address details of your company.</p>
                            <br />
                            <>
                                <p>Please select your country.</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "6" }}
                                        value={EnterpriseCountry ? EnterpriseCountry : "0"}
                                        // onChange={(e) => setEnterpriseCountry(e.target.value)}
                                        onChange={handleEnterPriseCountry}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your Country--</MenuItem>
                                        {updatedCountries.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                    {EnterPriseCountryError &&
                                        <p style={{ color: 'red', margin: '5px 0px' }} >{EnterPriseCountryError}</p>
                                    }
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Please select your state.</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "7" }}
                                        value={EnterpriseState ? EnterpriseState : "0"}
                                        // onChange={(e) => setEnterpriseState(e.target.value)}
                                        onChange={handleEnterPriseState}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your State--</MenuItem>
                                        {AllStates.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                    {EnterPriseStateError &&
                                        <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseStateError} </p>
                                    }
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Please select your city.</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "8" }}
                                        value={EnterpriseCity ? EnterpriseCity : "0"}
                                        // onChange={(e) => setEnterpriseCity(e.target.value)}
                                        onChange={handleEnterPriseCity}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your City--</MenuItem>
                                        {AllCities.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                    {EnterPriseCityError &&
                                        <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseCityError} </p>
                                    }
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Company's Address</p>
                                <input
                                    type="text"
                                    placeholder='Enter the address here'
                                    value={EnterpriseAddress}
                                    tabIndex={9}
                                    // onChange={(e) => setEnterpriseAddress(e.target.value)}
                                    onChange={handleEnterPriseCompanyAddress}
                                />
                                {EnterPriseCompanyAddressError &&
                                    <p style={{ color: 'red', margin: '5px 0px' }} >{EnterPriseCompanyAddressError} </p>
                                }
                            </>
                            <br />
                            <>
                                <p>Pincode</p>
                                <input
                                    type="text"
                                    placeholder='Enter the pincode here eg : 121009'
                                    tabIndex={10}
                                    value={EnterprisePincode}
                                    // onChange={(e) => setEnterprisePincode(e.target.value)}
                                    onChange={handleEnterPrisePinCode}
                                />
                                {EnterPrisePincodeError &&
                                    <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPrisePincodeError} </p>
                                }
                            </>
                        </div>



                        <div id='Type1' >
                            <h3>Year of establishment </h3>
                            <p>Kindly select the year of establishment of your company.</p>
                            <FormControl style={{ width: "100%" }}>
                                <Select
                                    inputProps={{ tabIndex: "11" }}
                                    value={EnterpriseEstablishmentYear ? EnterpriseEstablishmentYear : currentYear}
                                    // onChange={(e) => setEnterpriseEstablishmentYear(e.target.value)}
                                    onChange={handleEnterPriseEstablishmentYear}
                                    style={{ width: "100%", height: "42px", color: '#666666' }}
                                >
                                    {
                                        yearsArray.map((year, index) => {
                                            return <MenuItem key={index} value={year} > {year} </MenuItem>
                                        })
                                    }
                                </Select>
                                {EnterpriseEstablishmentYearError &&
                                    <p style={{ color: 'red', margin: '5px 0px' }}>{EnterpriseEstablishmentYearError} </p>
                                }
                            </FormControl>
                        </div>

                        <div id='Type1' >
                            <h3>About Us </h3>
                            <p>Please provide a brief description for the company.</p>
                            <OutlinedInput
                                multiline
                                rows={6}  // Adjust the number of rows as needed
                                type="text"
                                inputProps={{ tabIndex: "12" }}
                                value={EnterpriseAboutUs}
                                // onChange={(e) => setEnterpriseAboutUs(e.target.value)}
                                onChange={handleEnterPriseAboutus}
                                style={{ width: "100%" }}
                                placeholder='Write here'
                            />
                            {EnterPriseAboutusError &&
                                <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseAboutusError} </p>
                            }
                        </div>

                        <div id='Type1' >
                            <h3>Company's CIN Number </h3>
                            <p>Please enter the CIN Number of the company.</p>
                            <input
                                type="text"
                                placeholder='Enter CIN here'
                                value={EnterpriseCinNumber}
                                tabIndex={13}
                                onChange={(e) => setEnterpriseCinNumber(e.target.value)}
                            />
                        </div>





                        <div id='Type1' >
                            <h3>Company's Website Link </h3>
                            <p>Please provide the link of your company's website.</p>
                            <input
                                type="text"
                                placeholder='Enter the link here'
                                value={EnterpriseWebsiteLink}
                                tabIndex={14}
                                onChange={(e) => setEnterpriseWebsiteLink(e.target.value)}
                            // onChange={handleEnterPriseWebsiteLink}
                            />
                            {EnterPriseWebsiteLinkError &&
                                <p style={{ color: 'red', margin: '5px 0px' }}>{EnterPriseWebsiteLinkError} </p>
                            }
                        </div>

                        {/* <div id='Type1'>
                            <h3>E Mail Address</h3>
                            <h2 style={{ display: 'flex', padding: '0px', alignItems: 'center' }} >
                                <ContactMailRounded style={{ color: '#232a58', marginRight: '10px' }} />
                                mohit.chauhan2275@gmail.com
                            </h2>

                            <Accordion style={{ boxShadow: '0px 0px 0px white' }} >
                                <AccordionSummary
                                    id="panel1-header"
                                >
                                    <p>You will be contacted via this email address
                                        <span>Change Email Id.</span></p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Enter Email ID to get OTP </p>
                                            <input
                                                type="number"
                                                placeholder='Enter OTP'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Get OTP
                                        </Button>
                                    </div>

                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Verify OTP recieved</p>
                                            <input
                                                type="number"
                                                placeholder='Enter Mobile Number'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div> */}






                        {/* <h2 style={{ display: 'flex', padding: '0px', alignItems: 'center' }} >
                                <Call style={{ color: '#232a58', marginRight: '10px' }} />
                                8295552737
                                <DeleteOutline style={{ color: 'indianRed', marginLeft: "13px" }} />
                            </h2> */}

                        {/* <Accordion style={{ boxShadow: '0px 0px 0px white' }} >
                                <AccordionSummary id="panel1-header" >
                                    <p> <span> Add New Number</span> </p>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Enter number to get OTP </p>
                                            <input
                                                type="number"
                                                placeholder='Enter Mobile Number'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Get OTP
                                        </Button>
                                    </div>

                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Verify OTP recieved </p>
                                            <input
                                                type="number"
                                                placeholder='Enter OTP'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion> */}


                        <div>
                            {
                                CheckCompanyProfileID == "true" &&
                                <Button
                                    onClick={HandleEditEnterprise}
                                    className='Button_1'
                                    variant="contained"
                                    tabIndex={14}
                                    style={{ marginRight: '12px' }}
                                >
                                    Update
                                </Button>
                            }

                            {
                                CheckCompanyProfileID == "false" &&
                                <Button
                                    onClick={HandleCreateEnterprise}
                                    className='Button_1'
                                    variant="contained"
                                    tabIndex={14}
                                    style={{ marginRight: '12px' }}
                                >
                                    Complete Details
                                </Button>
                            }

                            <Button
                                onClick={HandleCancelButton}
                                className='Button_2'
                                variant="outlined"
                                color="inherit"
                                tabIndex={15}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                </Box>
            </Modal >
        </>
    )
}

export default EnterpriseProfilePage
