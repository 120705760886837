import React from 'react';
import SeekerHomePage from '../Home Pages/SeekerHomePage';
import EnterpriseHomePage from './EnterpriseHomePage';
import AdminHomePage from './AdminHomePage';


const HomePage = () => {
    const Account_Type = localStorage.getItem("Account_Type");

    return (
        <>
            {
                Account_Type == "Job_Seeker" &&
                <SeekerHomePage />
            }

            {
                Account_Type == "Enterprise" &&
                <EnterpriseHomePage />
            }

            {
                Account_Type == "Admin" &&
                <AdminHomePage />
            }
        </>
    )
}

export default HomePage;
