import React from 'react';
import './AdminProfilePage.css'

const AdminProfilePage = () => {


    
    return (
        <div>
            This is admin profile page
        </div>
    )
}

export default AdminProfilePage
